import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Top from '../components/home/Top'
import Info from '../components/home/Info'

const IndexPage = props => {
  const data = props.data.dis.pageHome
  const images = props.data.dis.assetsConnection.edges
  // const videos = data.introGallery
  const videos = data.introVideos

  let items = images

  videos && videos.forEach(vid => {
    items.push(vid)
  })

  const shuffledItems = items
    .map(a => ({ sort: Math.random(), value: a }))
    .sort((a, b) => a.sort - b.sort)
    .map(a => a.value)

  return (
    <div>
      <SEO title="Home" />

      <Top carouselItems={shuffledItems} />
      
      <div id="d-01"></div>
      
      <Info
        infoTitle={data.infoTitle}
        infoLead={data.infoLead}
        infoText={data.infoText}
        loopVideo={data.infoVideo}
        // loopVideo={data.videoLoopSource}
        fullVideo={data.fullVideo}
        // fullVideo={data.fullVideoSource}
        materialNames={data.materialNames}
        materialsGallery={data.materialsGallery}
        materialsCta={data.materialsCta}
        designersCta={data.designersCta}
      />

      <div id="l-01"></div>
      <div id="d-02"></div>
      <div id="l-02"></div>
      <div id="d-03"></div>
      <div id="l-03"></div>
      <div id="d-04"></div>
      <div id="l-04"></div>
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query PageHomeQuery {
    dis {
      pageHome(where: { id: "cki5vfyw05d0y0a011976vdkz" }) {
        designersCta
        infoLead
        infoText
        infoTitle
        materialNames
        introGallery(where: {mimeType: "video/mp4"}, first: 4) {
          url
          mimeType
        }
        introVideos
        materialsGallery {
          handle
          width
          height
          url
        }
        materialsCta
        designersCta
        infoVideo {
          mimeType
          url
        }
        fullVideo {
          mimeType
          url
        }
        videoLoopSource
        fullVideoSource
        seoDescription
        seoImage {
          url
        }
        seoTitle
      }
      assetsConnection(where: { mimeType_contains: "image" }, orderBy: id_DESC, stage: PUBLISHED, first: 40) {
        edges {
          node {
            handle
            width
            height
          }
        }
      }
    }
  }
`
