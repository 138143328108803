import React, { useRef, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

import TopImage from './TopImage'
import TopVideo from './TopVideo'

import W from '../styled/Wrapper'
import Follow from '../shared/Follow'

const fadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`

const moveUp = keyframes`
  0% { transform: translateY(100%) }
  100% { transform: translateY(0%) }
`

const Intro = styled(W)`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  transform: translateZ(0);
  top: 0;
  left: 0;
  right: 0;
  z-index: ${({ hideTop }) => (hideTop ? -1 : 0)};
  opacity: ${({ hideTop }) => (hideTop ? 0 : 1)};
  pointer-events: ${({ hideTop }) => (hideTop ? 'none' : 'auto')};
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: ${({ theme }) => theme.white};

  @media only screen and (min-width: 1024px) {
    padding-top: 0;
  }
`

const Gallery = styled.div`
  width: 100%;
  opacity: 0;
  animation: ${fadeIn} 0.4s 1s forwards;
  position: relative;
  z-index: -1;
  background: ${({ init }) =>
    init ? 'none' : `url('../discovered-placeholder2.svg  ') center no-repeat`};
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 720px;
  max-height: 720px;
  transform: translate3d(0, 0, 1px);
  overflow: hidden;

  @media only screen and (min-width: 600px) {
  }

  @media only screen and (orientation: landscape) {
    width: 70vh;
    height: 60vh;
  }

  @media only screen and (orientation: portrait) {
    width: 70vw;
    height: 70vw;
  }

  @media only screen and (orientation: portrait) and (max-width: 600px) {
    width: 100%;
    height: calc(100vw - 40px);
  }
`

const Discovered = styled.img`
  position: absolute;
  margin: 0 auto;
  bottom: 0;
  max-width: 90vw;
  height: auto;
  transform: translateY(100%);
  opacity: ${({show}) => show ? 1 : 0};
  animation: ${moveUp} 0.8s 0.3s forwards cubic-bezier(0.83, 0, 0.17, 1);

  @media only screen and (min-width: 1024px) {
    max-width: 990px;
  }
`

const Top = ({ carouselItems }) => {
  const [hideTop, setHideTop] = useState(false)
  const [init, setInit] = useState(false)
  const [showLogo, setShowLogo] = useState(false)

  const [posX, setPosX] = useState(null)
  const [posY, setPosY] = useState(null)
  const [showCursor, setShowCursor] = useState(false)

  const galleryEl = useRef(null)

  let startX = 0
  let startY = 0
  let flagInit = false
  let lastActiveImg = 0

  // const myValSqrd = typeof window !== 'undefined' ? window.innerWidth / 7 : 250
  const myValSqrd = 130

  // mobile timeout
  let mobileInterval
  const mobileAnimationTime = 1

  const handleScroll = e => {
    setHideTop(e.target.scrollTop > window.innerHeight)
  }

  function mobileAnimation() {
    const gal = galleryEl.current
    const activeEl = gal.querySelector('.active')

    runActive(activeEl, gal)

    if (flagInit === false) {
      initCarousel(gal)
    }
  }

  function initCarousel(gallery) {
    const firstItem = gallery.children[lastActiveImg]

    if (firstItem) {
      firstItem.classList.add('active')

      if (firstItem && firstItem.hasAttribute('data-adres')) {
        if (firstItem.getAttribute('data-adres') !== '') {
          firstItem.setAttribute('src', firstItem.getAttribute('data-adres'))
        }
        firstItem.removeAttribute('data-adres')
      }

      // preload next one
      const nextToPreload = gallery.querySelector('[data-adres]')
      if (nextToPreload) {
        if (nextToPreload.getAttribute('data-adres') !== '') {
          nextToPreload.setAttribute(
            'src',
            nextToPreload.getAttribute('data-adres')
          )
        }
        nextToPreload.removeAttribute('data-adres')
      }
    }

    flagInit = true
    setInit(true)
  }

  function runActive(el, gallery) {
    if (el && el.nextElementSibling) {
      el.nextElementSibling.classList.add('active')

      if (el.nextElementSibling.tagName === 'VIDEO')
        el.nextElementSibling.play()

      const nextImg = el.nextElementSibling

      if (nextImg && nextImg.hasAttribute('data-adres')) {
        if (nextImg.getAttribute('data-adres') !== '') {
          nextImg.setAttribute('src', nextImg.getAttribute('data-adres'))
        }
        nextImg.removeAttribute('data-adres')
      }

      // preload next one
      const nextToPreload = gallery.querySelector('[data-adres]')
      if (nextToPreload) {
        if (nextToPreload.getAttribute('data-adres') !== '') {
          nextToPreload.setAttribute(
            'src',
            nextToPreload.getAttribute('data-adres')
          )
        }
        nextToPreload.removeAttribute('data-adres')
      }

      lastActiveImg = Array.from(gallery.children).indexOf(el) + 1
    } else {
      lastActiveImg = 0
      gallery.children[lastActiveImg].classList.add('active')
      if (gallery.children[lastActiveImg].tagName === 'VIDEO')
        gallery.children[lastActiveImg].play()
    }

    el && el.classList.remove('active')
    if (el && el.tagName === 'VIDEO') el.pause()
  }

  const onToTheNextOne = () => {
    const gal = galleryEl.current

    clearInterval(mobileInterval)

    const activeEl = gal.querySelector('.active')

    runActive(activeEl, gal)

    mobileInterval = setInterval(mobileAnimation, mobileAnimationTime * 1000)
  }

  const scrollToContent = () => {
    document.body.scroll({
      top: window.innerHeight,
      left: 0,
      behavior: 'smooth',
    })
  }

  const initMouseEnter = e => {
    startX = e.pageX
    startY = e.pageY

    // set coordinates when entering and init if not
    if (flagInit === false) {
      initCarousel(galleryEl.current)
    }
  }

  const initMouseMove = e => {
    if (!showCursor) {
      setShowCursor(true)
    }

    setPosX(e.pageX)
    setPosY(e.pageY)

    if (flagInit) {
      let mouseX = e.pageX
      let mouseY = e.pageY

      let basePointX = startX - mouseX
      let basePointY = startY - mouseY

      let basePointSqrd = Math.sqrt(
        Math.pow(basePointX, 2) + Math.pow(basePointY, 2)
      )

      if (basePointSqrd > myValSqrd) {
        const activeEl = galleryEl.current.querySelector('.active')

        runActive(activeEl, galleryEl.current)

        startX = mouseX
        startY = mouseY
      }
    }
  }

  const initMouseLeave = e => {
    const firstItem = galleryEl.current?.querySelector('.active')

    firstItem && firstItem.classList.remove('active')

    flagInit = false
    setInit(false)
    setShowCursor(false)
  }

  useEffect(() => {
    document.body.addEventListener('scroll', handleScroll)
    setShowLogo(true)

    return () => {
      document.body.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const isTouch =
      typeof window !== 'undefined'
        ? 'ontouchstart' in window || navigator.msMaxTouchPoints > 0
        : false

    const gal = galleryEl.current
    const galleryWrap = gal.parentElement

    if (gal && gal.children.length > 1) {
      if (!isTouch) {
        gal.addEventListener('mouseenter', initMouseEnter)

        galleryWrap.addEventListener('mousemove', initMouseMove)

        galleryWrap.addEventListener('mouseleave', initMouseLeave)

        galleryWrap.addEventListener('click', scrollToContent)
      } else {
        // mobile animation

        mobileInterval = setInterval(
          mobileAnimation,
          mobileAnimationTime * 1000
        )

        // click

        galleryWrap.addEventListener('click', onToTheNextOne, false)
      }
    }

    return () => {
      galleryEl.current.removeEventListener('mouseenter', initMouseEnter)
      galleryEl.current.parentElement.removeEventListener(
        'mousemove',
        initMouseMove
      )
      galleryEl.current.parentElement.removeEventListener(
        'mouseleave',
        initMouseLeave
      )
      galleryEl.current.parentElement.removeEventListener(
        'click',
        scrollToContent
      )
      galleryEl.current.parentElement.removeEventListener(
        'click',
        onToTheNextOne
      )
      clearInterval(mobileInterval)
      mobileInterval = null
    }
  }, [galleryEl])

  return (
    <React.Fragment>
      <Intro hideTop={hideTop}>
        <Gallery ref={galleryEl} init={init}>
          {carouselItems &&
            carouselItems.length > 0 &&
            carouselItems.map((item, i) => {
              if (item.url) {
                return <TopVideo key={i} url={item.url} />
              } else if (typeof item === 'string') {
                return <TopVideo key={i} url={item} />
              } else {
                if ((i + 1) % 5 === 0) {
                  return (
                    <TopImage key={i} cross={'../discovered-placeholder2.svg'} count={i} />
                  )
                } else {
                  return <TopImage key={i} item={item} count={i} />
                }
              }
            })}
        </Gallery>

        <Discovered as="img" src={'/discovered.svg'} show={showLogo} />
      </Intro>
      <Follow intro posX={posX} posY={posY} show={showCursor} />
    </React.Fragment>
  )
}

export default Top
