import React, { useEffect, useState } from 'react'
import Ticker from 'react-ticker'
import styled from 'styled-components'
import PageVisibility from 'react-page-visibility'

import H from '../styled/Heading'

const Wrapper = styled.div`
  margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '48px')};

  @media only screen and (min-width: 768px) {
    margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '53px')};
  }

  @media only screen and (min-width: 1024px) {
    margin-bottom: 30px;
  }

  .ticker {
    &__element {
      display: flex;
      flex-wrap: nowrap;
      min-width: 0;
    }
  }
`

const TextElement = styled(H)`
  white-space: nowrap;
  min-width: 0;
  overflow: visible;
  padding-right: 90px;
  display: inline-block;
  /* transform: translateZ(0); */

  @media only screen and (min-width: 1024px) {
    padding-right: 170px;
  }
`

const ImageElement = styled.div`
  width: 196px;
  height: 121px;
  margin: 0 10px;

  @media only screen and (min-width: 768px) {
    height: 200px;
    width: 326px;
  }

  @media only screen and (min-width: 1024px) {
    width: 421px;
    height: 258px;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Carousel = ({ dir, content, noMargin }) => {
  const [show, setShow] = useState(false)
  const [pageIsVisible, setPageIsVisible] = useState(true)

  const handleVisibilityChange = isVisible => {
    setPageIsVisible(isVisible)
  }

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 100)
  }, [])

  return (
    <Wrapper noMargin={noMargin}>
      <PageVisibility onChange={handleVisibilityChange}>
        {show && pageIsVisible && (
          <Ticker direction={dir} offset={1} show={show} speed={7}>
            {({ index }) =>
              content &&
              content.length > 0 &&
              content.map((el, i) => {
                if (typeof el == 'string') {
                  return (
                    <TextElement key={i} size={1}>
                      {el}
                    </TextElement>
                  )
                } else {
                  return (
                    <ImageElement key={i}>
                      <Image
                        src={` https://media.graphcms.com/resize=w:421,h:258,fit:crop/${el.url}`}
                      />
                    </ImageElement>
                  )
                }
              })
            }
          </Ticker>
        )}
      </PageVisibility>
    </Wrapper>
  )
}

export default Carousel
