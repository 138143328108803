import React from 'react'
import styled from 'styled-components'

const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  opacity: 0;
  transition: opacity .05s;

  will-change: transform;
  -webkit-backface-visibility: hidden;

  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;

  &.active {
    opacity: 1;
  }

  &.landscape {
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 1px);
  }

  &.portrait {
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 1px);
  }
`

const TopVideo = ({ url }) => {

  return (
    
    <Video
        className={'landscape'}
        muted
        playsInline
        loop
        src={url}
        poster={'../discovered-placeholder2.svg'}
      />

  )
}

export default TopVideo
