import React from 'react'
import styled from 'styled-components'

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  opacity: 0;
  transition: opacity .05s;

  will-change: transform;
  -webkit-backface-visibility: hidden;

  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;

  &.active {
    opacity: 1;
  }

  &.landscape {
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 1px);
  }

  &.portrait {
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 1px);
  }
`

const TopImage = ({ item, cross, count }) => {

  // initially preload 5 images
  if(item) {

    return (

      <Image
          className={item.node.width > item.node.height ? 'landscape' : 'portrait'}
          src={count < 5 ? `https://media.graphcms.com/resize=w:800,h:800/${item.node.handle}` : ''}
          data-adres={count >= 5 ? `https://media.graphcms.com/resize=w:800,h:800/${item.node.handle}` : ''}
        />

    )
  } else {
    return (
      <Image
          className={'landscape'}
          src={cross}
        />
    )
  }
}

export default TopImage
