import React from 'react';
import { Link } from 'gatsby';
import styled, { keyframes } from 'styled-components';


const move = keyframes`
  0% {
   transform: translate(0px, -50%);
	 opacity: 1;
  }

	17% {
    transform: translate(5px, -50%);
		opacity: 0.5;
	}
	34%{
    transform: translate(10px, -50%);
	  opacity: 0;
		
	}
	50% {
    transform: translate(-10px, -50%);
	  opacity: 0;
  }
  67% {

    transform: translate(-5px, -50%);
    opacity: 0.5;
  }
  100% {
    transform: translate(0px, -50%);
    opacity: 1;
  }
`;

const Button = styled(({ color, ...rest }) => <Link {...rest} />)`
  display: block;
  font-family: ${({ theme }) => theme.fontSans};
  font-size: 18px;
  line-height: 55px;
  text-transform: uppercase;
  border: 1px solid ${({ theme }) => theme.blackOpaque};
  border-color: ${({ theme, color }) => color == 'white' ? theme.whiteOpaque : theme.blackOpaque};
  padding: 2px 30px 0;
  color: inherit;
  transition: border-color .2s;
  position: relative;
  width: 100%;
  max-width: 422px;

  &:after {
    content: '';
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 13px;
    height: 13px;
    background: url('../icons/arrow${({ theme, color }) => color == 'white' ? '-white' : ''}.svg') center no-repeat;
    background-size: contain;
  }

  @media (hover: hover) {
    &:hover {
      border-color: ${({ theme, color }) => color == 'white' ? theme.white : theme.black};
      &:after {
        animation: ${move} 0.6s ease-in-out;
      }
    }

  
  }

`
export default Button
