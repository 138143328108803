import React from 'react'
import styled from 'styled-components'

import ImgVideo from '../shared/ImgVideo'

import H from '../styled/Heading'
import P from '../styled/Paragraph'
import B from '../styled/Button'
import W from '../styled/Wrapper'

import Carousel from './Carousel'
import Newsletter from '../layout/Newsletter'

const SectionWrapper = styled.section`
  position: sticky;
  pointer-events: none;
`

const Wrapper = styled(W)`
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: ${({ autoHeight }) => (autoHeight ? 'initial' : '100vh')};
  padding-top: ${({ autoHeight }) => (autoHeight ? '60px' : '114px')};
  padding-bottom: 114px;
  pointer-events: auto;

  @media only screen and (min-width: 1024px) {
    padding-top: ${({ autoHeight }) => (autoHeight ? '0' : '152px')};
    padding-bottom: ${({ autoHeight }) => (autoHeight ? '160px' : '50px')};
    min-height: auto;
  }
`

const Header = styled.header`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 115px;
  }

  @media only screen and (min-width: 1024px) {
    margin-bottom: 110px;
  }
`

const Title = styled(H)`
  width: 100%;
  text-align: center;
  margin-bottom: 39px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 49px;
  }

  @media only screen and (min-width: 1024px) {
    margin-bottom: 49px;
  }
`

const Lead = styled(P)`
  max-width: 1242px;
  align-self: flex-start;
  margin-bottom: 46px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 51px;
  }

  @media only screen and (min-width: 1024px) {
    margin-bottom: 63px;
    width: 80%;
  }
`

const Text = styled(P)`
  max-width: 1010px;
  align-self: flex-end;

  @media only screen and (min-width: 768px) {
    width: 82%;
  }

  @media only screen and (min-width: 1024px) {
    width: 60%;
  }
`

const VideoSection = styled.figure`
  max-width: 890px;
  margin-bottom: 50px;

  @media only screen and (min-width: 1024px) {
    width: 60%;
    margin-bottom: 75px;
  }
`

const DesignersLink = styled(B)``

const Tickers = styled.div`
  background-color: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};

  @media only screen and (min-width: 1024px) {
    padding: 100px 0 50px;
  }
`

const MaterialsLink = styled(B)`
  margin-bottom: 120px;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 160px;
  }
`

const Info = ({
  infoTitle,
  infoLead,
  infoText,
  loopVideo,
  fullVideo,
  designersCta,
  materialsGallery,
  materialNames,
  materialsCta,
}) => {
  return (
    <SectionWrapper>
      <Wrapper>
        <Header>
          <Title size={1}>{infoTitle}</Title>

          <Lead size="l">{infoLead}</Lead>

          <Text size="m">{infoText}</Text>
        </Header>

        <VideoSection>
          <ImgVideo loop={loopVideo} full={fullVideo} narrow />
        </VideoSection>

        <DesignersLink to="/designers" color="white">
          {designersCta}
        </DesignersLink>
      </Wrapper>

      <Tickers>
        <Carousel dir="toRight" content={materialNames} />

        <Carousel dir="toLeft" content={materialsGallery} noMargin />
      </Tickers>

      <Wrapper autoHeight>
        <MaterialsLink to="/materials" color="white">
          {materialsCta}
        </MaterialsLink>
        <Newsletter />
      </Wrapper>
    </SectionWrapper>
  )
}

export default Info
